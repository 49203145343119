.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.widget-block-tour {
		.widget-header {
			h3 {
				margin: 0;
				display: flex;
				justify-content: space-between;

				a {
					color: var(--color-white);
				}
			}
		}
	}
}