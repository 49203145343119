.regionen-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner.region-8359 {

		//background-image: url(RESOURCE/img/zollhaus.jpg);

		background-image: url(RESOURCE/img/Norden1.jpg);

	}

	.inner-banner.region-8360 {

		//background-image: url(RESOURCE/img/zollhaus.jpg);

		background-image: url(RESOURCE/img/norddeich-banner.jpg);
		background-position: bottom center;

	}

	.inner-banner.region-8365 {

		//background-image: url(RESOURCE/img/zollhaus.jpg);

		background-image: url(RESOURCE/img/lighthouse-Grimersum.jpg);

	}

	.inner-banner.region-8364 {

		//background-image: url(RESOURCE/img/zollhaus.jpg);

		background-image: url(RESOURCE/img/nesse-banner.jpg);

	}

	.inner-banner.region-8363 {

		//background-image: url(RESOURCE/img/zollhaus.jpg);

		background-image: url(RESOURCE/img/Nessler4.jpg);

	}

	.inner-banner.region-8361 {
		background-image: url(RESOURCE/img/hage-banner.jpg);

	}
}